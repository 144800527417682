import React from 'react'
import {Button, Result} from 'antd'
import {useNavigate} from 'react-router-dom'

export const Page404 = () => {
    const navigate = useNavigate()

    return (
        <Result status='404' title='404' subTitle='Такой страницы не существует :(' extra={<Button type='primary' onClick={
            () => navigate(-1)}>Вернутся</Button>
        } />
    )
}