import {Space} from 'antd'
import {useAppSelector} from '../store'
import {selectClients} from '../store/slices/clientsSlice'
import {Loader} from '../shared/utils/Loader'
import {FAKE_NAMES} from '../shared/properties'
import {getRandomElement} from '../store/slices/random'
import {CaretRightOutlined, PauseOutlined} from '@ant-design/icons'
import {formatTime} from '../shared/utils/timeFormatter';

export const ClientList = () => {
    const { clients, loading } = useAppSelector(selectClients)
    
    return (
        <Loader loading={loading} children={() =>
            <Space style={{ margin: 10 }} direction='vertical'>
                {clients.map(client => <div id={client.id}>
                    <Space size={0}>
                        <div>{client.paused ? <CaretRightOutlined /> : <PauseOutlined />}</div>
                        <div>{client.name}</div>
                    </Space>
                    <div>{formatTime(client.time)}</div>
                </div>)}
            </Space>
        } />
    )
}