// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  padding: 0;
  margin: 0;
}

.max-width {
  width: 100%;
}

.player-wrapper {
  position: relative;
}

.player-feature {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.hide {
  opacity: 0;
  cursor: none;
}

.show {
  opacity: 1;
  cursor: default;
}

.player-blackout {
  transition: opacity 250ms;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
}

.player-bottom {
  transition: opacity 500ms;
  padding-left: 2px;
  padding-right: 5px;
}

.player-button {
  -webkit-user-select: none;
          user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/app/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;AACb;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":["body, html {\n  padding: 0;\n  margin: 0;\n}\n\n.max-width {\n  width: 100%;\n}\n\n.player-wrapper {\n  position: relative;\n}\n\n.player-feature {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n}\n\n.hide {\n  opacity: 0;\n  cursor: none;\n}\n\n.show {\n  opacity: 1;\n  cursor: default;\n}\n\n.player-blackout {\n  transition: opacity 250ms;\n  background-color: rgba(0, 0, 0, 0.2);\n  height: 100%;\n}\n\n.player-bottom {\n  transition: opacity 500ms;\n  padding-left: 2px;\n  padding-right: 5px;\n}\n\n.player-button {\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
