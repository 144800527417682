import {Button, Layout, theme} from 'antd'
import React from 'react'
import { WEBSITE_NAME } from '../shared/properties'
import {useNavigate} from 'react-router-dom'
const { useToken } = theme

export const AppHeader = () => {
    const navigate = useNavigate()
    const { token } = useToken()

    return (
        <Layout.Header style={{ backgroundColor: token.colorBgBase, borderBottom: token.colorBorderSecondary + ' solid 2px' }}>
            <Button type='text' style={{ fontSize: 20, height: 'auto', marginLeft: -30 }} onClick={() => navigate('/')}>{WEBSITE_NAME}</Button>
        </Layout.Header>
    )
}