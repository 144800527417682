export const WEBSITE_NAME = 'GravityWatch'
export const SOCKET_URL = (process.env.REACT_APP_DEBUG == "true" ? 'ws://localhost:8000/ws/' : 'wss://gravity.slavita.dev/ws/')

export const FAKE_NAMES = [
    'Неопознанный клоун',
    'Неопознанный гандопляс',
    'Неопознанный червь',
    'Опознанный червь',
    'Интернет-гейрой',
    'Интернет-zлодей',
    'Неопознанное чучело',
    'Кто-то из космоса',
    'Никто',
    'Кто',
    'Олух'
]