import {ServerErrorPage} from '../errors/ServerErrorPage'
import React from 'react'
import {Layout} from 'antd'
import {Helmet} from 'react-helmet'
import {WEBSITE_NAME} from '../../shared/properties'

interface Props {
    title: string
    error?: boolean
}

export const PageGate = ({ children, title, error = false }: React.PropsWithChildren<Props>) => {
    if (error) return <ServerErrorPage />

    return (
        <Layout.Content style={{ padding: 30, overflow: 'auto' }}>
            <Helmet title={title + ' | ' + WEBSITE_NAME} />
            {children}
        </Layout.Content>
    )
}