import {ConfigProvider, Layout, theme} from 'antd'
import React from 'react'
import './index.css'
import {AppHeader} from '../widgets/AppHeader'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {RoomPage} from '../pages/RoomPage'
import {Page404} from '../pages/errors/Page404'

const App = () => {
    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
                token: {
                    colorBgBase: '#1f1f1f',
                },
            }}
        >
            <Layout style={{ height: '100vh' }}>
                <BrowserRouter>
                    <AppHeader />
                    <Routes>
                        <Route path='/' element={ <RoomPage /> } />
                        <Route path='/*' element={ <Page404 /> } />
                    </Routes>
                </BrowserRouter>
            </Layout>
        </ConfigProvider>
    )
}

export default App
