import {RawPlayer} from '../entities/RawPlayer'
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import Player from 'video.js/dist/types/player'
import {Button, Dropdown, Slider, Space} from 'antd'
import {
    BlockOutlined,
    CaretRightOutlined,
    FullscreenExitOutlined, FullscreenOutlined,
    PauseOutlined,
    SelectOutlined, SoundOutlined
} from '@ant-design/icons'
import {ClientList} from '../entities/ClientList'
import {formatTime} from '../shared/utils/timeFormatter';
import {PlayerSlider} from '../entities/PlayerSlider'
import 'videojs-contrib-quality-levels'
import 'videojs-youtube'
import {isYoutube} from "../shared/utils/isYoutube";

type UpdateFunction = (time: number, paused: boolean) => void

interface Props {
    src: string
    onTimeUpdate: UpdateFunction
    onManualTimeUpdate: UpdateFunction
    onPausedChanged: UpdateFunction
    onPlayerInitialized: () => void
}

export interface PlayerHandle {
    setPaused: (paused: boolean) => void
    setTime: (time: number) => void
}

export const CustomPlayer = forwardRef<PlayerHandle, Props>(({ src, onTimeUpdate, onManualTimeUpdate, onPausedChanged, onPlayerInitialized }, ref) => {
    const time = useRef<number>(0)
    const playerRef = useRef<Player>()
    const idleInterval = useRef<NodeJS.Timeout>()
    const [mouseMoved, setMouseMoved] = useState(false)
    const [paused, setPaused] = useState(true)
    const [ready, setReady] = useState(false)
    const [pip, setPip] = useState(false)
    const [volume, setVolume] = useState(50)
    const [fullscreen, setFullscreen] = useState(false)
    
    useImperativeHandle(ref, () => ({
        setPaused(paused: boolean) {
            if (paused) pause()
            else play()
        },
        setTime(newTime: number) {
            if (Math.abs(newTime - time.current) > 0.3) setTime(newTime)
        },
    }))

    useEffect(() => {
        // @ts-ignore
        playerRef.current!!.on('fullscreenchange', () => {
            setFullscreen(playerRef.current!!.isFullscreen()!!)
        })

        // @ts-ignore
        playerRef.current!!.qualityLevels().on('addqualitylevel', (event: any) => {
            // const qualityLevel = event.qualityLevel
            // qualityLevel.enabled = qualityLevel.height == 720
            // console.log(qualityLevel)

            let player = playerRef.current!!
            // @ts-ignore
            console.log(player.qualityLevels())

            // @ts-ignore
            let qualityLevels = player.qualityLevels()
            for (let qualityLevel of qualityLevels) {
                qualityLevel.enabled = qualityLevel.id === qualityLevels[qualityLevels.length - 1].id
            }
            qualityLevels.selectedIndex_ = qualityLevels.length - 1;
            // @ts-ignore
            console.log(qualityLevels)

            // // @ts-ignore
            // const qualityLevels = playerRef.current!!.qualityLevels()
            // for (let i = 0; i < qualityLevels.length; i++) {
            //     let qualityLevel = qualityLevels[i];
            //     qualityLevel.enabled = i === qualityLevels.length - 1;
            // }
            //
            // qualityLevels.selectedIndex_ = qualityLevels.length - 1;
            // qualityLevels.trigger({ type: 'change', selectedIndex: qualityLevels.length - 1 });
        })
    }, [])
    
    useEffect(() => {
        if (playerRef.current) onPlayerInitialized()
    }, [onPlayerInitialized, playerRef])
    
    useEffect(() => {
        const timeInterval = setInterval(() => {
            time.current = playerRef.current!!.currentTime()!!
            onTimeUpdate(time.current, paused)
        }, 70)

        playerRef.current!!.on('enterpictureinpicture', () => {
            setPip(true)
            playerRef.current!!.controls(true)
        })

        playerRef.current!!.on('leavepictureinpicture', () => {
            setPip(false)
            playerRef.current!!.controls(false)
        })

        playerRef.current!!.on('volumechange', () => {
            setVolume(playerRef.current!!.volume()!! * 100)
        })
        
        return () => {
            clearTimeout(timeInterval)
        }
    }, [onTimeUpdate, paused])
    
    useEffect(() => {
        if (ready) {
            if (isYoutube(src)) {
                playerRef.current!!.src({
                    type: 'video/youtube',
                    src: src,
                })
                playerRef.current!!.height(720)
            } else {
                playerRef.current!!.src(src)
                playerRef.current!!.height(undefined)
            }
        }
    }, [ready, src])
    
    const play = () => {
        //save play() that prevents error (https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error)
        //fix is nsot full, sometimes error is shown
        
        const video = playerRef.current!!.player()
        // @ts-ignore
        const isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA
        
        if (!isPlaying) { // @ts-ignore
            video.play()
        }
        setPaused(false)
    }

    const pause = () => {
        clearTimeout(idleInterval.current)
        playerRef.current!!.pause()
        setPaused(true)
    }

    const switchPaused = () => {
        if (!paused) pause()
        onPausedChanged(playerRef.current!!.currentTime()!!, !paused)
    }

    const switchFullscreen = () => {
        setFullscreen(!fullscreen)
        if (!fullscreen) playerRef.current?.requestFullscreen()
        else playerRef.current?.exitFullscreen()
    }
   
    const setTime = (time: number) => playerRef.current!!.currentTime(time)
    
    const skipBackward = () => {
        onManualTimeUpdate(time.current - 5, paused)
    }
    
    const skipForward = () => {
        onManualTimeUpdate(time.current + 5, paused)
    }

    const changeTime = (newTime: number) => {
        onManualTimeUpdate(newTime, paused)
    }
    
    const switchPip = () => {
        if (pip) playerRef.current!!.exitPictureInPicture()
        else playerRef.current!!.requestPictureInPicture()
    }
    
    const mouseMove = () => {
        setMouseMoved(true)
        clearTimeout(idleInterval.current)
        idleInterval.current = setTimeout(() => {
            setMouseMoved(false)
        }, 1500)
    }
    
    const keyDown = (repeat: boolean, key: string) => {
        if (repeat) return
        
        switch(key) {
            case ' ': { switchPaused(); break }
            case 'ArrowLeft': { skipBackward(); break }
            case 'ArrowRight': { skipForward(); break }
            case 'f': { switchFullscreen(); break }
        }
    }

    const getContainer = () => document.fullscreenElement ? document.getElementById(document.fullscreenElement.id) || document.body : document.body

    return (
        <div
            tabIndex={-1}
            onMouseMove={mouseMove}
            onClick={switchPaused}
            onKeyDown={(e) => { keyDown(e.repeat, e.key); e.stopPropagation() }}
        >
            <RawPlayer playerRef={playerRef} onReady={() => setReady(true)}>
                {ready && !pip && <>
                    <div className={'player-feature player-blackout ' + (paused || mouseMoved ? 'show' : 'hide')} />
                    <div className={'player-feature ' + (paused || mouseMoved ? 'show' : 'hide')} style={{ top: 0 }}>
                        <ClientList />
                    </div>
                    <div className={'player-feature player-bottom ' + (paused || mouseMoved ? 'show' : 'hide')} onClick={(e) => e.stopPropagation()}>
                        <div className='max-width' style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button
                                type='text'
                                tabIndex={-1}
                                className='player-button'
                                onKeyDown={(e) => e.preventDefault()}
                                onClick={switchPaused}
                                icon={paused ? <CaretRightOutlined /> : <PauseOutlined />}
                            />
                            <Dropdown placement='topCenter' trigger={['click']} overlayStyle={{zIndex:2000}} getPopupContainer={getContainer} dropdownRender={() =>
                                <Slider
                                    vertical
                                    defaultValue={50}
                                    step={5}
                                    tooltip={{
                                        getTooltipContainer: getContainer
                                    }}
                                    value={volume}
                                    onChange={(value) => {playerRef.current!!.volume(value / 100); setVolume(value)}}
                                    style={{ height: "10vh", zIndex: 2200 }}
                                />
                            }>
                                <Button
                                    type='text'
                                    tabIndex={-1}
                                    className='player-button'
                                    onKeyDown={(e) => e.preventDefault()}
                                    icon={<SoundOutlined />}
                                />
                            </Dropdown>
                            <PlayerSlider
                                duration={playerRef.current!!.duration()!!}
                                currentTimeRef={time}
                                onChange={changeTime}
                            />
                            {!pip &&
                                <Button type='text' onClick={switchPip}>
                                    {pip ? <SelectOutlined /> : <BlockOutlined />}
                                </Button>
                            }
                            {!pip &&
                                <Button
                                    type='text'
                                    tabIndex={-1}
                                    className='player-button'
                                    onKeyDown={(e) => e.preventDefault()}
                                    onClick={switchFullscreen}
                                    icon={fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                                />
                            }
                        </div>
                    </div>
                </>}
            </RawPlayer>
        </div>
    )
})