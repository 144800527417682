import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../index'

export interface Client {
    id: string
    name: string
    time: number
    paused: boolean
}

export interface ClientsState {
    clients: Client[]
    loading: boolean
}

const slice = createSlice({
    name: 'clients',
    initialState: { clients: [], loading: true } as ClientsState,
    reducers: {
        setLoading: state => {
            state.loading = true
        },
        setClients: (state, action: PayloadAction<Client[]>) => {
            state.clients = action.payload
            state.loading = false
        },
    }
})

export const selectClients = (state: RootState) => state.clients

export const { setLoading, setClients } = slice.actions

export default slice.reducer