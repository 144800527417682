import {Button, Result} from 'antd'
import React from 'react'
import {useNavigate} from 'react-router-dom'

export const ServerErrorPage = () => {
    const navigate = useNavigate()

    return (
        <Result status='500' title='Ошибка' subTitle='Ой, что то пошло не так' extra={
            <Button type='primary' onClick={() => navigate(0)}>Повторить</Button>
        } />
    )
}