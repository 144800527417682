import React from 'react'
import {Space, Spin} from 'antd'
import {ServerErrorPage} from '../../pages/errors/ServerErrorPage'

interface Props {
    loading: boolean
    error?: boolean
    skeleton?: React.ReactNode
    children: () => React.ReactNode
}

export const Loader = ({ children, skeleton = undefined, loading, error = false }: Props) => {
    if (error) return <ServerErrorPage />
    if (!loading) return <>{children()}</>
    if (skeleton) return <>{skeleton}</>

    return (
        <Space className='max-width' direction='vertical' align='center'>
            <Spin />
        </Space>
    )
}