import {PageGate} from './utils/PageGate'
import 'video.js/dist/video-js.css'
import {CustomPlayer} from '../features/CustomPlayer'
import {Button, Input, Space} from 'antd'
import {useState} from 'react'
import {WatchClient} from '../widgets/watch-client/WatchClient'

export const RoomPage = () => {
    return (
        <PageGate title='Комната'>
            <WatchClient />
        </PageGate>
    )
}