import {CustomPlayer, PlayerHandle} from '../../features/CustomPlayer'
import {Button, Input, Space} from 'antd'
import {useEffect, useRef, useState} from 'react'
import {FAKE_NAMES, SOCKET_URL} from '../../shared/properties'
import {SocketMessage} from './messages'
import {setLoading, setClients, Client, selectClients} from '../../store/slices/clientsSlice'
import {dispatch, useAppSelector} from '../../store'
import {ClientList} from '../../entities/ClientList'
import {getRandomElement} from '../../store/slices/random'

export const WatchClient = () => {
    const socket = useRef<WebSocket>()
    const [playerInitialized, setPlayerInitialized] = useState(false)
    const [srcInput, setSrcInput] = useState('')
    const [refererInput, setRefererInput] = useState('')
    const [src, setSrc] = useState('')
    const [name, setName] = useState('')
    const controller = useRef<PlayerHandle>(null)
    const { loading } = useAppSelector(selectClients)
    
    useEffect(() => {
        if (!playerInitialized) return
        socket.current = new WebSocket(SOCKET_URL)
    }, [playerInitialized])
    
    useEffect(() => {
        send({ action: 'update_name', name })
    }, [name])
    
    useEffect(() => {
        if (!playerInitialized) return
        if (!socket.current) return
        
        socket.current!!.onmessage = (e) => {
            const data = JSON.parse(e.data) as SocketMessage
            
            if (data.action === 'initialize' || data.action === 'clients_update') dispatch(setClients(data.clients))
            if (data.action === 'initialize' || data.action === 'set_paused') controller.current!!.setPaused(data.paused)
            if (data.action === 'initialize' || data.action === 'set_time' || data.action === 'set_paused') controller.current!!.setTime(data.time)
            if (data.action === 'initialize' || data.action === 'set_src') setSrc(data.src)
            
            if (data.action === 'initialize') {
                let current_name = name
                if (name === '') {
                    current_name = getRandomElement(FAKE_NAMES)
                    setName(current_name)
                }
                send({ action: 'update_name', name: current_name })
            }
        }
        
        const onClosed = () => {
            dispatch(setLoading())
            setTimeout(() => socket.current = new WebSocket(SOCKET_URL), 2000)
        }
        
        socket.current!!.onclose = onClosed
        socket.current!!.onerror = onClosed
        
        return () => socket.current?.close()
    }, [playerInitialized, socket])
    
    const send = (data: SocketMessage) => {
        if (socket.current?.readyState !== 1) return
        socket.current.send(JSON.stringify(data))
    }
    
    const onPausedChanged = (time: number, paused: boolean) => send({ action: 'set_paused', time, paused })
    
    const onTimeUpdate = (time: number, paused: boolean) => {
        if (loading) return
        send({ action: 'update_self', time, paused })
    }
    
    const onManualTimeUpdate = (time: number, paused: boolean) => {
        send({ action: 'set_time', time, paused })
    }
    
    const sendSrc = () => {
        send({ action: 'set_src', src: srcInput, referer: refererInput })
        setSrcInput('')
    }
    
    return (
        <Space className='max-width' direction='vertical' align='center'>
            <CustomPlayer
                ref={controller}
                src={src}
                onTimeUpdate={onTimeUpdate}
                onManualTimeUpdate={onManualTimeUpdate}
                onPausedChanged={onPausedChanged}
                onPlayerInitialized={() => setPlayerInitialized(true)}
            />
            
            <Input placeholder='Username' onInput={e => setName(e.currentTarget.value)} value={name} name='graviname' />
            
            <Space.Compact direction='horizontal'>
                <Input placeholder='Url' onInput={e => setSrcInput(e.currentTarget.value)} value={srcInput} />
                <Input placeholder='Referer' onInput={e => setRefererInput(e.currentTarget.value)} value={refererInput} />
                <Button onClick={sendSrc}>Send</Button>
            </Space.Compact>
        </Space>
    )
}