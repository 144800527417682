import {formatTime} from '../shared/utils/timeFormatter'
import {Slider} from 'antd'
import {MutableRefObject, useState} from 'react'

interface Props {
    duration: number
    currentTimeRef: MutableRefObject<number>
    onChange: (time: number) => void
}

export const PlayerSlider = ({ duration, currentTimeRef, onChange }: Props) => {
    const [editing, setEditing] = useState(false)
    const [sliderValue, setSliderValue] = useState(0)
    
    return (
        <Slider
            style={{ flexGrow: 1 }}
            max={duration}
            value={editing ? sliderValue : currentTimeRef.current}
            tooltip={{ formatter: (value) => <>{formatTime(value)}</> }}
            onChange={(value) => {
                setEditing(true)
                setSliderValue(value)
            }}
            onAfterChange={(value) => {
                onChange(value)
                setEditing(false)
            }}
        />
    )
}